import Api from "../services/Api";

export default class Meetings {

    // Busca reuniões
    static get = async () => {
        const api = new Api();
        return await api.get('meetings');
    }

    // Busca todas reuniões
    static getAll = async () => {
        const api = new Api();
        return await api.get('meetings/all');
    }

    // Salva
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('meetings/' + data.id.toString(), data);
        } else {
            result = await api.post('meetings', data);
        }
        return result;
    }

    // Marca Presença
    static saveUserMeeting = async (data) => {
        const api = new Api();
        return await api.put('usermeeting', data);
    }

    // Remove todos os presentes
    static removePresents = async (meeting) => {
        const api = new Api();
        return await api.put('userspresence/clear/' + meeting.id)
    }
}

