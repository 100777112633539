import Api from "../services/Api";

export default class Results {

    // Busca Resultados
    static get = async (votingID) => {
        const api = new Api();
        return await api.get('results/' + votingID.toString());
    }

    // Salva resultado
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('results/' + data.id.toString(), data);
        } else {
            result = await api.post('results', data);
            const id = result.response.insertId;
            let result2 = await api.get('results/' + id.toString(), null);
            if (result2.response.length > 0) {
                result2 = await api.get('results/mail/' + result2.response[0].token);
                console.log(result2);
            }
        }
        return result;
    }

}

