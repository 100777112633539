import Api from "../services/Api";

export default class Voting {

    // Busca uma Votação em Aberto
    static get = async (meetingID) => {
        const api = new Api();
        const result = await api.get('voting/' + meetingID.toString());
        if (result !== null) {
            const actives = result.response.filter(item => item.status === 'Ativa');
            if (actives.length > 0) {
                return actives[0];
            } else {
                const finisheds = result.response.filter(item => item.status==='Encerrada');
                if (finisheds.length > 0) {
                    return finisheds[finisheds.length-1];
                } else {
                    return result.response[0];
                }
            }
        }
        return null;
    }

    // Busca todoas as votações de uma reunião
    static getAll = async (meetingID) => {
        const api = new Api();
        return await api.get('votings/' + meetingID.toString());
    }

    // Salva
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('voting/' + data.id.toString(), data);
        } else {
            result = await api.post('voting', data);
        }
        return result;
    }

    // duplicate
    static duplicate = async (data) => {
        const api = new Api();
        let result = null;
        result = await api.post('voting/copy/' + data.id.toString(), data);
        return result;
    }
}

