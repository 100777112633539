import React from 'react';
import { Avatar, Button, IconButton, Tooltip } from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';
import IconLogin from '@material-ui/icons/PersonAdd';
import IconExit from '@material-ui/icons/ExitToApp';
import Global from '../Globals';
import { ArrowBack, EventNote, PeopleAlt, Wifi, WifiOff } from '@material-ui/icons';

const UserBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: '100%',
    height: '72px',
    borderBottom: '1px solid #f2f2f2',
    marginBottom: '16px'
})

const useStyles = makeStyles((theme) => ({
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: theme.spacing(2)
    },
    title: {
        ...theme.typography.subtitle2,
    },
    subTitle: {
        ...theme.typography.body2,
        fontSize: '10px'
    },
    avatar: {
        marginRight: '24px'
    },
    emailBtn: {
        marginLeft: '16px'
    },
    readBtn: {
        marginLeft: '16px'
    },
    loginBtn: {
        marginRight: '24px'
    },
    wifi: {
        width: 13,
        height: 13,
        position: 'relative',
        top: '+2px'
    },
    buttonsDiv: {
        marginRight: '16px'
    }
}));

export default function UserControl(props) {
    const classes = useStyles();

    // Dados do usuário Avatar
    const userDetails = () => (
        <div className={classes.flexRow}>
            {props.area === 'MainArea' && Global.user.type === "Administrador" ?
                <div className={classes.buttonsDiv}>
                    <Tooltip title={'Reuniões'}>
                        <IconButton
                            onClick={() => props.setArea('MeetingsArea')}
                            style={{ color: Global.institution.primary_color }}>
                            <EventNote />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Usuários'}>
                        <IconButton
                            onClick={() => props.setArea('UsersArea')}
                            style={{ color: Global.institution.primary_color }}>
                            <PeopleAlt />
                        </IconButton>
                    </Tooltip>
                </div> : null}
            <div className={classes.flexCol}>
                <div className={classes.title}>
                    {Global.user.name}
                </div>
                <div className={classes.subTitle} style={{ color: Global.institution.primary_color }}>
                    {Global.meeting === null ? (Global.user.type === 'Administrador' ? 'Administrador' : Global.institution.name) : (Global.user.type === 'Administrador' ? 'Admin | ' + Global.meeting.user_type : Global.meeting.user_type)} {Global.socket.connected ? <Wifi className={classes.wifi} /> : <WifiOff className={classes.wifi} />}
                </div>
            </div>
            <Avatar className={classes.avatar} style={{ backgroundColor: Global.institution.primary_color }}>
                {Global.user.name.substr(0, 1)}
            </Avatar>
        </div>
    );

    // Botão de login
    const loginBtn = () => (
        <div className={classes.flexRow}>
            <Button onClick={() => props.onLogin()} className={classes.loginBtn} style={{ color: Global.institution.primary_color }} endIcon={<IconLogin />}>Entrar</Button>
        </div>
    );

    // Sair
    const exitBtn = () => {
        localStorage.removeItem('token');
        Global.socket.disconnect();
        window.location.reload();
    }

    return (
        <UserBar style={{ backgroundColor: `${Global.institution.primary_color}30` }}>
            {props.area === 'MainArea' ?
                <div>
                    {Global.user !== null ?
                        <Tooltip title={'Sair do sistema'}>
                            <IconButton className={classes.emailBtn} onClick={() => exitBtn()} style={{ color: Global.institution.primary_color }} >
                                <IconExit />
                            </IconButton>
                        </Tooltip>
                        :
                        null}
                </div> :
                (
                    props.area === 'VotingsArea' ?
                        <div>
                            <Tooltip title={'Voltar para tela de reuniões'}>
                                <IconButton 
                                    className={classes.emailBtn} 
                                    onClick={() => {
                                        Global.timer === null || clearTimeout(Global.timer);
                                        Global.timer = null;
                                        props.setArea('MeetingsArea')}
                                    } 
                                    style={{ color: Global.institution.primary_color }} 
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                        </div>
                        :
                        <div>
                            <Tooltip title={'Voltar para tela de votação'}>
                                <IconButton 
                                    className={classes.emailBtn} 
                                    onClick={() => props.setArea('MainArea')} 
                                    style={{ color: Global.institution.primary_color }} 
                                >
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                        </div>
                )
            }
            {Global.user !== null ? userDetails() : loginBtn()}
        </UserBar>
    )
}


