import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainArea from './components/MainArea';
import Global from './Globals';
import UserControl from './components/UserControl';
import useInstitution from './hooks/Institution';
import useUser from './hooks/User';
import LoginDlg from './components/LoginDlg';
import Socket from './services/Socket';
import Meetings from './models/Meetings';
import MeetingsArea from './components/MeetingsArea';
import UsersArea from './components/UsersArea';
import VotingsArea from './components/VotingsArea';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%"
  },
}));

// App
export default function App() {
  const classes = useStyles();
  Global.institution = useInstitution();
  Global.user = useUser();
  /* eslint-disable no-unused-vars */
  const [meeting, setMeeting] = useState(null);
  /* eslint-enable no-unused-vars */
  const [statusMeeting, setStatusMeeting] = useState('');
  const [statusVoting, setStatusVoting] = useState('');
  const [statusSocket, setStatusSocket] = useState('');
  const [statusPresence, setStatusPresence] = useState(0);
  const [loginDlg, setLoginDlg] = useState(false);
  const [area, setArea] = useState('MainArea');
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  document.body.style.backgroundColor = `${Global.institution.primary_color}10`;

  // Mostra login
  const onLogin = () => {
    setLoginDlg(true);
  }

  // Socket mudou de status
  const onStatus = (message) => {
    if (message.action === 'socket') {
      setStatusSocket(message.data.status);
    } else if (message.action === 'meeting') {
      setStatusMeeting(message.data.status);
    } else if (message.action === 'voting') {
      setStatusVoting(message.data.status);
    } else if (message.action === 'logged') {
      setStatusSocket('logged');
    } else if (message.action === 'presence') {
      Global.editingDlg || setStatusPresence('Presente' + message.data.user_id);
    } else if (message.action === 'participant') {
      window.location.reload();
    }
  }

  // Verifica as reuniãoes
  const checkMeeting = () => {
    if (statusSocket === 'logged') {
      Meetings.get().then(result => {
        if (result !== null && result.response.length > 0) {
          console.log(result);
          Global.meeting = result.response[0];
          // Marca Presença
          if (Global.meeting.status === 'Ativa') {
            const data = {
              user_id: Global.user.id,
              meeting_id: Global.meeting.id,
              status: 'Presente'
            };
            Meetings.saveUserMeeting(data).then(result => {
              console.log("Enviando presença...");
              Global.socket.send('{"action": "presence", "message":"", "data": {"status": "Presente", "user_id": ' + data.user_id + '}}');
            });
          }
          setMeeting(Global.meeting);
        }
      });
    }
  }

  // Inicia o socket
  React.useEffect(() => {
    Global.socket = new Socket(onStatus);
    //checkMeeting();
  }, []);

  // Mudou o status do meeting
  React.useEffect(checkMeeting, [statusMeeting, statusSocket]);

  // Mostra area com lista de votações
  const showVotingsArea = (meeting) => {
    setSelectedMeeting(meeting);
    console.log(selectedMeeting);
    setArea('VotingsArea');
  }

  // REseta selectedmeetings
  React.useEffect(() => {
    if (area !== 'VotingsArea')
      setSelectedMeeting(null);
  }, [area]);

  return (
    <div>
      <main className={classes.content}>
        <UserControl
          onLogin={onLogin}
          setArea={setArea}
          area={area}
        />
        {area === 'MainArea' ?
          <MainArea
            statusSocket={statusSocket}
            statusVoting={statusVoting} />
          :
          (area === 'MeetingsArea' ?
            <MeetingsArea
              key={statusPresence}
              showVotingsArea={showVotingsArea}
            />
            :
            (area === 'VotingsArea' ?
              <VotingsArea
                key={'votings' + (selectedMeeting !== null ? selectedMeeting.id : 0)}
                meeting={selectedMeeting}
              /> :
              <UsersArea />
            )
          )}
      </main>
      <LoginDlg open={loginDlg} setOpen={setLoginDlg} />
    </div>
  );
}