import React from 'react';
import { Typography, Fade, Chip } from '@material-ui/core'
import { styled } from '@material-ui/core/styles';
import VotingArea from './VotingArea';
import Global from '../Globals';
import Utils from '../utils/utils';

const Area = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: Global.userToken != null ? 'flex-start' : 'center',
    width: "100%",
    height: Global.userToken != null ? '' : 'calc(100vh - 200px)'
});

const Title = styled('div')({
    padding: '8px',
    textAlign: 'center',
});

export default function MainArea(props) {
    return (
        <Area>
            <Title style={{ color: Global.institution.primary_color }}>
                <Typography variant="subtitle2" >
                    SISTEMA DE VOTAÇÃO
                </Typography>
                <Fade in={Global.institution.name !== ''}>
                    <Typography variant="h5" >
                        {Global.institution.name}
                    </Typography>
                </Fade>
                {Global.meeting != null ?
                    <Fade in={Global.meeting !== null}>
                        <div>
                            <Chip
                                label={Global.meeting.title}
                                variant="outlined"
                                style={{ marginTop: 16, paddingLeft: 20, paddingRight: 20 }}
                            />
                            <br />
                            <Chip
                                label={Utils.dateFormat(Global.meeting.date_start)}
                                variant="outlined"
                                style={{ marginTop: 16, paddingLeft: 20, paddingRight: 20 }}
                            />
                        </div>
                    </Fade>
                    :
                    <Chip
                        label='Aguardando Próxima Reunião'
                        variant="outlined"
                        style={{ marginTop: 16, paddingLeft: 20, paddingRight: 20 }}
                    />}
            </Title>
            {Global.meeting != null && Global.meeting.status === 'Ativa' ?
                <VotingArea key={props.statusVoting + props.statusSocket} />
                :
                (Global.meeting != null ? <Fade in={Global.meeting !== null}>
                    <Chip
                        label={Global.meeting.status.toUpperCase()}
                        style={{ marginTop: 80, paddingLeft: 20, paddingRight: 20 }}
                        color='primary'
                    />
                </Fade> : null)}
        </Area>
    )
}
