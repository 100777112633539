import Global from '../Globals'

const url = Global.apiURL;

export default class Api {

    // Token
    token = "";
    userToken = "";
    headers = "";

    // Constructor
    constructor() {
        this.token = Global.institutionToken;
        this.userToken = Global.userToken;
        this.headers = { 'Content-Type': 'application/json', 'institutiontoken': this.token, 'usertoken': this.userToken };
    }

    // Method Get
    async get(resource) {
        const requestOptions = {
            method: 'GET',
            headers: this.headers
        };
        const response = await fetch(url + '/' + resource, requestOptions);
        const data = await response.json();
        return data;
    }

    // Method Post
    async post(resource, postData) {
        const requestOptions = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(postData)
        };
        const response = await fetch(url + '/' + resource, requestOptions);
        const data = await response.json();
        return data;
    }

    // Method PUT
    async put(resource, postData) {
        const requestOptions = {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(postData)
        };
        const response = await fetch(url + '/' + resource, requestOptions);
        const data = await response.json();
        return data;
    }

    // Method Delete
    async delete(resource) {
        const requestOptions = {
            method: 'DELETE',
            headers: this.headers
        };
        const response = await fetch(url + '/' + resource, requestOptions);
        const data = await response.json();
        return data;
    }
}