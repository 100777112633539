import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Global from '../Globals';

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex" style={{marginRight: '16px'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function MCirculasProgress(props) {
    return <CircularProgressWithLabel
        value={props.progress}
        style={{ color: Global.institution.primary_color }}
    />;
}
