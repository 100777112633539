import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { AddCircle, Cancel, ControlPointDuplicate, Delete, Edit, ExpandMore, Pause, PlayArrow, Stop } from '@material-ui/icons';
import Results from '../models/Results';
import { AccordionActions, Chip, Divider, IconButton, styled, Tooltip } from '@material-ui/core';
import Global from '../Globals';
import Voting from '../models/Voting';
import Subjects from '../models/Subjects';
import SubjectEditDialog from './SubjectEditDialog';
import MCircularProgress from './MCircularProgress';
import Users from '../models/Users';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const Title = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: "100%",
    height: "48px"
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});

export default function VotingsTable(props) {
    const [expanded, setExpanded] = React.useState(props.votings.lenght > 0 ? props.votings[0].id : 0);
    const [results, setResults] = React.useState([]);
    const [selectedVoting, setSelectedVoting] = React.useState(null);
    const [subjectDlg, setSubjectDlg] = React.useState(false);
    const [voters, setVoters] = React.useState(0);

    // Pega resultados
    const fetchResults = (votingID) => {
        Results.get(votingID).then(results => {
            console.log(results);
            setResults(results.response);
            setExpanded(votingID);
        });
    }

    // Pega votações
    React.useEffect(() => {
        if (selectedVoting != null && !subjectDlg)
            fetchResults(selectedVoting.id);
    }, [subjectDlg, selectedVoting]);

    // Abre e fecha acordion
    const handleChange = (panel) => (event, newExpanded) => {
        if (newExpanded) {
            setExpanded(false);
            setTimeout(() => fetchResults(panel), 300);
        } else {
            setExpanded(false);
        }
    };

    // Edita subject
    const onSubjectEdit = (voting) => {
        setSelectedVoting(voting);
        setSubjectDlg(true);
    }

    // Altera status de uma votação
    const setVotingStatus = (voting, status) => {
        voting.status = status;
        saveVoting(voting);
    }

    // Duplica votação
    const duplicateVoting = (voting) => {
        Voting.duplicate(voting).then(result => {
            console.log(result);
            props.onSaveVoting();
        });
    }

    // Apaga votação
    const deleteVoting = (voting) => {
        voting.deleted = 1;
        saveVoting(voting);
    }

    // Salva alterações na votação
    const saveVoting = (voting) => {
        Voting.save(voting).then(result => {
            console.log(result);
            Global.socket.send('{"action": "voting", "message":"", "data": {"status": "' + voting.status + voting.id + '"}}');
            voting.status !== 'Encerrada' || fetchResults(voting.id);
            props.onSaveVoting();
            if (voting.status==='Ativa') {
                localStorage.setItem("active_voting_id", voting.id);
            } else {
                localStorage.removeItem('active_voting_id');
            }
            fetchVoters();
        });
    }

    // Verifica votantes
    const fetchVoters = () => {
        const voting_id = localStorage.getItem('active_voting_id');
        if (voting_id !== null && voting_id !== undefined) {
            Users.fetchVoters(voting_id, Global.meeting).then(result => {
                console.log(result);
                if (result !== null && result.response.length > 0) {
                    const _presents = result.response[0].presents;
                    const _voters = result.response[0].voters;
                    setVoters(_voters * 100 / _presents);
                }
                Global.timer = setTimeout(() => fetchVoters(), 5000);
            });
        } 
    }

    // Inicia contagem de votanges
    React.useEffect(fetchVoters, []);

    // Apaga subject
    const deleteSubject = (subject) => {
        subject.deleted = 1;
        Subjects.save(subject).then(result => {
            console.log(result);
            fetchResults(subject.voting_id);
        });
    }

    return (
        <div>
            {props.votings.map(row =>
                <Accordion
                    TransitionProps={{ unmountOnExit: true, exit: true }}
                    key={row.id}
                    id={row.id}
                    square
                    expanded={expanded === row.id}
                    onChange={handleChange(row.id)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <Title>
                            <Typography>• {row.title}</Typography>
                            <Status>
                                {row.status !== 'Ativa' ? null :
                                    <MCircularProgress key={row.id} progress={Math.round(voters)} />
                                }
                                <Chip
                                    variant='outlined'
                                    label={'Votação ' + row.status}
                                    style={{ color: Global.institution.primary_color }}
                                />
                            </Status>
                        </Title>
                    </AccordionSummary>
                    <AccordionDetails>
                        {results.map(item =>
                            <Chip
                                key={'chip' + item.id + row.id}
                                label={item.subject + (row.status === 'Encerrada' ? ' - Votos: ' + item.votes : '')}
                                color="primary"
                                style={{ margin: 8 }}
                                onDelete={row.status === 'Aguardando' && item.subject !== 'Branco' && item.subject !== 'Nulo' ? () => deleteSubject(item) : null}
                            />
                        )}
                        {row.status === 'Aguardando' ?
                            <Tooltip title={'Adicionar novo item'}>
                                <IconButton
                                    onClick={() => onSubjectEdit(row)}
                                    color='primary'
                                >
                                    <AddCircle />
                                </IconButton>
                            </Tooltip> : null}
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        {row.status !== 'Aguardando' ?
                            <Tooltip title={'Desativar votação'}>
                                <IconButton
                                    onClick={() => setVotingStatus(row, 'Aguardando')}
                                    style={{ color: Global.institution.primary_color }}
                                >
                                    <Pause />
                                </IconButton>
                            </Tooltip> : null}
                        {row.status !== 'Ativa' ?
                            <Tooltip title={'Ativar votação'}>
                                <IconButton
                                    onClick={() => setVotingStatus(row, 'Ativa')}
                                    style={{ color: Global.institution.primary_color }}
                                >
                                    <PlayArrow />
                                </IconButton>
                            </Tooltip> : null}
                        <Tooltip title={'Encerrar votação'}>
                            <IconButton
                                onClick={() => setVotingStatus(row, 'Encerrada')}
                                style={{ color: Global.institution.primary_color }}
                            >
                                <Stop />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Cancelar votação'}>
                            <IconButton
                                onClick={() => setVotingStatus(row, 'Cancelada')}
                                style={{ color: Global.institution.primary_color }}
                            >
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Duplicar votação'}>
                            <IconButton
                                onClick={() => duplicateVoting(row)}
                                style={{ color: Global.institution.primary_color }}
                            >
                                <ControlPointDuplicate />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Editar votação'}>
                            <IconButton
                                onClick={() => props.onVotingEdit(row)}
                                style={{ color: Global.institution.primary_color }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Excluir votação'}>
                            <IconButton
                                onClick={() => deleteVoting(row)}
                                style={{ color: "#cc0000" }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </AccordionActions>
                </Accordion >
            )}
            <SubjectEditDialog
                key={'sub' + (selectedVoting === null ? 0 : selectedVoting.id)}
                voting={selectedVoting}
                open={subjectDlg}
                onClose={() => setSubjectDlg(false)}
            />
        </div >
    );
}
