import CryptoJS from "crypto-js";
import Global from "../Globals";

class Socket {

    connected = false;
    connection = null;
    callback = null;

    constructor(callback) {
        this.connected = false;
        this.callback = callback;
    }

    // Connect
    connect(email, institution_id) {
        const _this = this;
        this.connection = new WebSocket(Global.socketURL);

        // Connection opened
        this.connection.onopen = event => {
            const token = CryptoJS.MD5(email + institution_id);
            _this.connected = true;
            setTimeout(() => {
                _this.connection.send('{"action": "login", "message":"", "data": {"token": "' + token + '"}}');
            }, 1000)
            _this.callback===null || _this.callback({"action": "socket", "message": "", "data": {"status": "connected"}});
        };

        // Listen for messages
        this.connection.onmessage = event => {
            try {
                console.log('Message from server: ', event.data);
                _this.callback===null || _this.callback(JSON.parse(event.data));
            } catch (e) {
                console.log(e);
            }
        };

        // Connection closed
        this.connection.onclose = event => {
            _this.connected = false;
            _this.callback===null || _this.callback({"action": "socket", "message": "", "data": {"status": "closed"}});
            console.log('WebSocket closed! Trying to reconnect!');
            setTimeout(()=>{
                _this.connect(email, institution_id);
            }, 3000);
        };

        // Connection error
        this.connection.onerror = event => {
            _this.connected = false;
            _this.callback===null || _this.callback({"action": "socket", "message": "", "data": {"status": "error"}});
            console.log('WebSocket error! Trying to reconnect!');
            setTimeout(()=>{
                _this.connect(email, institution_id);
            }, 3000);
        };
    }

    // Envia mensagem
    send(message) {
        this.connection.send(message);
    }

    // Disconnect
    disconnect() {
        this.connection.close();
    }

}

export default Socket;