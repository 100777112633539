import Api from "../services/Api";

export default class Users {

    // Busca todos os usuários da instituição
    static getAll = async () => {
        const api = new Api();
        return await api.get('users');
    }

    // Busca todos os usuários da instituição com informações sobre sua participação em uma reunião
    static getParticipants = async (meeting) => {
        const api = new Api();
        const result = await api.get('participants/' + meeting.id);
        return result == null ? null : result;
    }

    // Remove um participante
    static removeParticipant = async (meeting, user) => {
        const api = new Api();
        const result = await api.delete('participant/' + meeting.id + '/' + user.id);
        return result == null ? null : result;
    }

    // Seta tipo de uma lista de participantes
    static setParticipants = async (meeting, users, tipo) => {
        const api = new Api();
        const data = {
            users: users,
            tipo: tipo,
            meeting_id: meeting.id
        }
        let result = null;
        result = await api.post('participants', data);
        return result;
    }

    // Pega todos os que já votaram
    static fetchVoters = async (voting_id, meeting) => {
        const api = new Api();
        return await api.get('results/percentage/' + voting_id + "/" + meeting.id);
    }

    // Salva
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('user/' + data.id.toString(), data);
        } else {
            result = await api.post('user', data);
        }
        return result;
    }

}

