import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Chip, IconButton, Menu, MenuItem, styled, Tooltip, Typography } from '@material-ui/core';
import { Apps, Delete, Edit, EventNote, PeopleAlt, TransferWithinAStation, Update } from '@material-ui/icons';
import Utils from '../utils/utils';
import Global from '../Globals';
import Meetings from '../models/Meetings';

const useStyles = makeStyles({
    table: {
        minWidth: 340,
    },
    icon: {
        marginRight: '12px'
    }
});

const Title = styled('div')({
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
});

const Root = styled('div')({
    width: 'calc(100% - 32px)',
    padding: '8px 16px'
});

const MyBadge = styled('span')({
    position: 'relative',
    top: '-8px',
    left: '8px',
    fontSize: '10px'
});

export default function MeetingsTable(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [meeting, setMeeting] = React.useState(null);

    const handleClick = (event, row) => {
        setMeeting(row)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (status) => {
        setAnchorEl(null);
        if (meeting != null && status != null) {
            const data = {
                id: meeting.id,
                institution_id: meeting.institution_id,
                date_start: Utils.dateToMysql(new Date(meeting.date_start)),
                date_end: Utils.dateToMysql(new Date(meeting.date_end)),
                title: meeting.title,
                local: meeting.local,
                name: meeting.name,
                status: status,
                link: meeting.link,
                active: meeting.active,
                updated_by: 'user',
                deleted: meeting.deleted
            };
            Meetings.save(data).then(result => {
                console.log(result);
                props.onUpdate();
                Global.socket.send('{"action": "meeting", "message":"", "data": {"status": "' + status + '"}}');
            })
        }
    };

    const removePresents = (meeting) => {
        Meetings.removePresents(meeting).then(result => {
            console.log(result);
            props.onUpdate();
            Global.socket.send('{"action": "participant", "message":"", "data": {"status": "Ausente"}}');
            window.location.reload();
        })
    }

    return (
        <Root>
            <Title>
                <EventNote className={classes.icon} />
                <Typography variant="subtitle2" >
                    REUNIÕES
                </Typography>
            </Title>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Reunião</TableCell>
                            <TableCell align="left">Início</TableCell>
                            <TableCell align="left">Término</TableCell>
                            <TableCell align="center">Participantes</TableCell>
                            <TableCell align="center">Presentes</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.meetings.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    • {row.title}
                                </TableCell>
                                <TableCell align="left">{Utils.dateFormat(row.date_start)}</TableCell>
                                <TableCell align="left">{Utils.dateFormat(row.date_end)}</TableCell>
                                <TableCell align="center">{row.participantes}</TableCell>
                                <TableCell align="center">
                                    {row.presentes}
                                    <MyBadge>
                                        {Math.round(row.presentes * 100 / (row.participantes > 0 ? row.participantes : 1)) + '%'}
                                    </MyBadge>
                                </TableCell>
                                <TableCell align="center">
                                    <Chip 
                                        label={row.status} 
                                        style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff' }} 
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={'Status'}>
                                        <IconButton
                                            onClick={event => handleClick(event, row)}
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            style={{ color: Global.institution.primary_color }}>
                                            <Update />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Remover presentes'}>
                                        <IconButton
                                            onClick={() => removePresents(row)}
                                            style={{ color: Global.institution.primary_color }}>
                                            <TransferWithinAStation />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Participantes'}>
                                        <IconButton
                                            onClick={() => props.onParticipants(row)}
                                            style={{ color: Global.institution.primary_color }}>
                                            <PeopleAlt />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Votações'}>
                                        <IconButton
                                            onClick={() => props.onVotings(row)}
                                            style={{ color: Global.institution.primary_color }}>
                                            <Apps />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Editar'}>
                                        <IconButton
                                            onClick={() => props.onEdit(row)}
                                            style={{ color: Global.institution.primary_color }}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Excluir'}>
                                        <IconButton style={{color: "#cc0000"}} onClick={() => props.onDelete(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="menu-status"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
            >
                <MenuItem onClick={() => handleClose('Aguardando')}>Aguardando</MenuItem>
                <MenuItem onClick={() => handleClose('Ativa')}>Ativa</MenuItem>
                <MenuItem onClick={() => handleClose('Encerrada')}>Encerrada</MenuItem>
                <MenuItem onClick={() => handleClose('Cancelada')}>Cancelada</MenuItem>
            </Menu>
        </Root>
    );
}
