import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar, Chip, IconButton, styled, Tooltip, Typography } from '@material-ui/core';
import { Delete, Edit, EventNote } from '@material-ui/icons';
import Global from '../Globals';

const useStyles = makeStyles({
    table: {
        minWidth: 340,
    },
    icon: {
        marginRight: '8px'
    },
    tableContainer: {
        marginBottom: '64px'
    }
});

const Title = styled('div')({
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
});

const Root = styled('div')({
    width: 'calc(100% - 32px)',
    padding: '8px 16px'
});

export default function UsersTable(props) {
    const classes = useStyles();
    return (
        <Root>
            <Title>
                <EventNote className={classes.icon} />
                <Typography variant="subtitle2" >
                    USUÁRIOS DO SISTEMA
                </Typography>
            </Title>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right" width={48}>&nbsp;</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Celular</TableCell>
                            <TableCell align="center">Acesso</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.users.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="center">
                                    <Avatar style={{ backgroundColor: Global.institution.primary_color }}>
                                        {row.name.substr(0, 1)}
                                    </Avatar>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.celular}</TableCell>
                                <TableCell align="center">
                                    <Chip label={row.type} style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff' }} />
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={'Editar'}>
                                        <IconButton
                                            onClick={() => props.onEdit(row)}
                                            style={{ color: Global.institution.primary_color }}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Excluir'}>
                                        <IconButton style={{color: "#cc0000"}} onClick={() => props.onDelete(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    );
}
