import React from 'react';
import { styled } from '@material-ui/core/styles';
import UsersTable from './UsersTable';
import Users from '../models/Users';
import { Fab } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import Global from '../Globals';
import UserEditDialog from './UserEditDialog';

const Area = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    width: "100%"
});

export default function UsersArea(props) {
    const [users, setUsers] = React.useState([]);
    const [user, setUser] = React.useState(null);
    const [editing, setEditing] = React.useState(false);

    // Edita usuário
    const onEditUser = (user) => {
        const data = user !== null ? user : {
            id: 0,
            institution_id: Global.institution.id,
            photo: '',
            name: '',
            email: '',
            celular: '',
            type: 'Comum',
            active: 1,
            deleted: 0
        }
        setUser(data);
        setEditing(true);
    }

    // On delete
    const onDelete = (data) => {
        data.deleted = 1;
        Users.save(data).then(result => {
            console.log(result);
            fetchAllUsers();
        });
    }

    // Busca todos os usuários
    const fetchAllUsers = () => {
        Users.getAll().then(result => {
            console.log(result);
            if (result !== null) {
                setUsers(result.response);
            }
        });
    }

    // Carrega quando fecha a dialogo de edição
    React.useEffect(() => {
        editing || fetchAllUsers();
    }, [editing])

    return (
        <Area>
            <UsersTable
                users={users}
                onUpdate={fetchAllUsers}
                onEdit={onEditUser}
                onDelete={onDelete} />
            <Fab
                style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff', position: 'fixed', bottom: 24, right: 24 }}
                onClick={() => onEditUser(null)}>
                <PersonAdd />
            </Fab>
            <UserEditDialog 
                key={user !== null ? user.id : 0} 
                user={user} 
                open={editing}
                onClose={() => setEditing(false)}/>
        </Area>
    )
}
