import React from 'react';
import { styled } from '@material-ui/core/styles';
import MeetingsTable from './MeetingsTable';
import Meetings from '../models/Meetings';
import { Fab } from '@material-ui/core';
import Global from '../Globals';
import { Add } from '@material-ui/icons';
import MeetingEditDialog from './MeetingEditDialog';
import Utils from '../utils/utils';
import ParticipantsDlg from './ParticipantsDlg';

const Area = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    width: "100%"
});

export default function MeetingsArea(props) {
    const [meetings, setMeetings] = React.useState([]);
    const [meeting, setMeeting] = React.useState(null);
    const [editing, setEditing] = React.useState(false);
    const [participants, setParticipants] = React.useState(false);

    // Edita Reunião
    const onEditMeeting = (meeting) => {
        const data = meeting !== null ? {
            id: meeting.id,
            institution_id: Global.institution.id,
            date_start: meeting.date_start,
            date_end: meeting.date_end,
            title: meeting.title,
            local: meeting.local,
            status: meeting.status,
            active: meeting.active,
            link: meeting.link,
            deleted: meeting.deleted
        } : {
                id: 0,
                institution_id: Global.institution.id,
                date_start: new Date().toISOString(),
                date_end: new Date().toISOString(),
                title: '',
                local: '',
                status: 'Aguardando',
                active: 1,
                deleted: 0,
                link: ' '
            };
        data.date_start = Utils.dateFormat(data.date_start);
        data.date_end = Utils.dateFormat(data.date_end);
        setMeeting(data);
        setEditing(true);
    }

    // on ParticipantsBTN
    const onParticipantsBtn = (meeting) => {
        const data = {
            id: meeting.id,
            institution_id: Global.institution.id,
            date_start: meeting.date_start,
            date_end: meeting.date_end,
            title: meeting.title,
            local: meeting.local,
            status: meeting.status,
            active: meeting.active,
            link: meeting.link,
            deleted: meeting.deleted
        };
        data.date_start = Utils.dateFormat(data.date_start);
        data.date_end = Utils.dateFormat(data.date_end);
        setMeeting(data);
        setParticipants(true);
    }

    // On delete
    const onDelete = (data) => {
        data.deleted = 1;
        data.date_start = Utils.dateToMysql(new Date(data.date_start));
        data.date_end = Utils.dateToMysql(new Date(data.date_end));
        Meetings.save(data).then(result => {
            console.log(result);
            fetchAllMeetings();
        });
    }

    // BUsca todas as reuniões
    const fetchAllMeetings = () => {
        Meetings.getAll().then(result => {
            console.log(result);
            if (result !== null) {
                setMeetings(result.response);
            }
        });
    }

    // Carrega quando fecha a dialogo de edição
    React.useEffect(() => {
        Global.editingDlg = editing || participants;
        editing || participants || fetchAllMeetings();
    }, [editing, participants, props]);

    return (
        <Area>
            <MeetingsTable
                key={'meeting' + meetings.length}
                meetings={meetings}
                onUpdate={fetchAllMeetings}
                onEdit={onEditMeeting}
                onDelete={onDelete}
                onParticipants={onParticipantsBtn}
                onVotings={props.showVotingsArea}
            />
            <Fab
                style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff', position: 'fixed', bottom: 24, right: 24 }}
                onClick={() => onEditMeeting(null)}>
                <Add />
            </Fab>
            <MeetingEditDialog
                key={meeting !== null ? meeting.id : 0}
                meeting={meeting}
                open={editing}
                onClose={() => setEditing(false)}
            />
            <ParticipantsDlg
                key={'participants' + (meeting === null || meeting.id)}
                open={participants}
                meeting={meeting}
                onClose={() => setParticipants(false)}
            />
        </Area>
    )
}
