import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Checkbox, Chip, InputAdornment, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Global from '../Globals';
import Users from '../models/Users';
import { Cancel, Search } from '@material-ui/icons';

const DialogContent = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        width: 480,
        height: 360
    },
}))(MuiDialogContent);

const TextField = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))(MuiTextField);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    table: {
        minWidth: '100%',
        marginTop: 8
    },
});

export default function ParticipantsDlg(props) {
    const classes = useStyles();
    const [participants, setParticipants] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [selected, setSelected] = React.useState([]);

    // Carrega participants
    const fetchParticipants = () => {
        props.meeting === null || Users.getParticipants(props.meeting).then(result => {
            console.log(result);
            setParticipants(result.response);
            setSelected([]);
        })
    };

    // Check all
    const checkAll = (event) => {
        if (event.target.checked) {
            let newSelected = [];
            participants.filter(item => filter !== '' ? (item.name.includes(filter)) : true).map(item => newSelected.push(item.id));
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    }

    // Inicia carregando os participantes
    React.useEffect(fetchParticipants, []);

    // Check boxes
    const toggleCheck = (event, row) => {
        const selectedIndex = selected.indexOf(row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    // Remove um participante da reunião
    const onRemove = (event, row) => {
        Users.removeParticipant(props.meeting, row).then(result => {
            console.log(result);
            fetchParticipants();
            Global.socket.send('{"action": "participant", "message":"", "data": {"status": "Removido"}}');
        });
    }

    // Altera tipo dos participantes
    const onUpdateType = (type) => {
        if (selected.length > 0)
            Users.setParticipants(props.meeting, selected, type).then(result => {
                console.log(result);
                fetchParticipants();
                Global.socket.send('{"action": "participant", "message":"", "data": {"status": "' + type + '"}}');
            });
    }

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                Participantes {props.meeting === null ? '' : props.meeting.title}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="search"
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Filtro"
                    value={filter}
                    onChange={event => setFilter(event.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Search /></InputAdornment>,
                    }}
                />
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    style={{ color: Global.institution.primary_color }}
                                    checked={selected.length === participants.length}
                                    onChange={event => checkAll(event)}
                                />
                            </TableCell>
                            <TableCell align="left">USUÁRIO</TableCell>
                            <TableCell align="center">TIPO</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {participants.filter(item => filter !== '' ? (item.name.includes(filter)) : true).map(row => (
                            <TableRow key={row.id}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        style={{ color: Global.institution.primary_color }}
                                        checked={selected.indexOf(row.id) !== -1}
                                        onChange={event => toggleCheck(event, row)}
                                    />
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell align='right'>
                                    {row.participant_type !== '' ?
                                        <Chip
                                            onDelete={event => onRemove(event, row)}
                                            size="small"
                                            label={row.participant_type}
                                            deleteIcon={<Cancel style={{ color: '#ffffff' }} />}
                                            style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff' }}
                                        /> : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ color: Global.institution.primary_color }}
                    onClick={() => onUpdateType('Visitante')}
                >
                    Definir visitantes
                </Button>
                <Button
                    style={{ color: Global.institution.primary_color }}
                    onClick={() => onUpdateType('Participante')}
                >
                    Definir participantes
                </Button>
                <Button onClick={props.onClose} style={{ color: Global.institution.primary_color }}>
                    Concluir
                </Button>
            </DialogActions>
        </Dialog>
    );
}
