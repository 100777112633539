import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Fab, makeStyles, Typography } from '@material-ui/core';
import Global from '../Globals';
import { Add, Today } from '@material-ui/icons';
import Voting from '../models/Voting';
import VotingsTable from './VotingsTable';
import VotingEditDialog from './VotingEditDialog';

const Area = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    width: "100%"
});

const Title = styled('div')({
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
});

const Root = styled('div')({
    width: 'calc(100% - 32px)',
    padding: '8px 16px'
});

const useStyles = makeStyles({
    table: {
        minWidth: 340,
    },
    icon: {
        marginRight: '12px'
    }
});

export default function VotingsArea(props) {
    const classes = useStyles();
    const [votings, setVotings] = React.useState([]);
    const [voting, setVoting] = React.useState(null);
    const [votingDlg, setVotingDlg] = React.useState(false);

    // BUsca todas as votações
    const fetchAllVotings = () => {
        votingDlg || Voting.getAll(props.meeting.id).then(result => {
            console.log(result);
            if (result !== null) {
                setVotings(result.response);
            }
        });
    }

    // Pega votações
    React.useEffect(fetchAllVotings, [votingDlg]);

    // Edita votação
    const onVotingEdit = (voting) => {
        setVoting(voting);
        setVotingDlg(true);
    }

    return (
        <Area>
            <Root>
                <Title>
                    <Today className={classes.icon} />
                    <Typography variant="subtitle2" >
                        {props.meeting !== null ? props.meeting.title.toUpperCase() : ''} | VOTAÇÕES
                    </Typography>
                </Title>
                <VotingsTable
                    votings={votings}
                    onVotingEdit={onVotingEdit}
                    onSaveVoting={fetchAllVotings}
                />
                <Fab
                    style={{ backgroundColor: Global.institution.primary_color, color: '#ffffff', position: 'fixed', bottom: 24, right: 24 }}
                    onClick={() => onVotingEdit({})}>
                    <Add />
                </Fab>
                <VotingEditDialog
                    key={voting === null ? 0 : voting.id}
                    meeting={props.meeting}
                    voting={voting}
                    open={votingDlg}
                    onClose={() => setVotingDlg(false)}
                />
            </Root>
        </Area>
    )
}
