import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { MenuItem, withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Global from '../Globals';
import Users from '../models/Users';

const DialogContent = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        width: 480
    },
}))(MuiDialogContent);

const TextField = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))(MuiTextField);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserEditDialog(props) {
    const [user, setUser] = React.useState(props.user === null ? {} : props.user);

    // Edição
    const onChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value })
    }

    // Salvar
    const onSaveBtn = () => {
        Users.save(user).then(result => {
            console.log(result);
            props.onClose();
        });
    }

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {user.id === 0 ? "Adicionar Usuário" : "Editar Usuário"}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="name"
                    required
                    fullWidth
                    variant="outlined"
                    label="Nome"
                    value={user.name}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="email"
                    required
                    fullWidth
                    variant="outlined"
                    label="Email"
                    value={user.email}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="celular"
                    fullWidth
                    variant="outlined"
                    label="Celular"
                    value={user.celular}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="type"
                    fullWidth
                    select
                    variant="outlined"
                    label="Tipo de acesso"
                    value={user.type}
                    onChange={event => onChange(event)}
                >
                    <MenuItem value='Comum'>Comum</MenuItem>
                    <MenuItem value='Administrador'>Administrador</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} style={{color: Global.institution.primary_color}}>
                    Fechar
                </Button>
                <Button onClick={onSaveBtn} style={{color: Global.institution.primary_color}}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
