import Api from "../services/Api";

export default class Subjects {

    // Busca Votação em Aberto
    static get = async (votingID) => {
        const api = new Api();
        return await api.get('subjects/' + votingID.toString());
    }

    // Salva
    static save = async (data) => {
        const api = new Api();
        let result = null;
        if (data.id > 0) {
            result = await api.put('subjects/' + data.id.toString(), data);
        } else {
            result = await api.post('subjects', data);
        }
        return result;
    }

}

