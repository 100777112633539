// Variáveis globais
const Global = {
    apiURL: 'https://api.votacao.dzign-e.app', // 'https://api.reservas.dzign-e.app',
    socketURL: 'ws://ws.votacao.dzign-e.app',
    institutionToken: window.location.search.substr(1),
    userToken: localStorage.getItem('token'),
    user: null,
    institution: {
        name: "",
        primary_color: '#d0d0d0',
        secondary_color: '#000000'
    },
    meeting: null,
    socket: null,
    editingDlg: false,
    timer: null
}

export default Global;