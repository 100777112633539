import React, { useState, useEffect } from 'react';
import CryptoJS from "crypto-js";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { TextField, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VerifiedIcon from '@material-ui/icons/Check';
import User from '../models/User';
import Global from '../Globals';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// Diálogo de Login
export default function LoginDlg(props) {
    const [doLogin, setDoLogin] = useState(false);
    const { open, setOpen } = props;
    const [id, setID] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [message, setMessage] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [verified, setVerified] = useState(false);

    // Verifica o email
    const checkEmail = () => {
        if (email.trim() !== '') {
            User.getByEmail(email.trim()).then((result) => {
                if (result.response.length > 0) {
                    let user = result.response[0];
                    setID(user.id);
                    setName(user.name);
                    setEmail(user.email);
                    setCelular(user.celular);
                    setDoLogin(true);
                } else {
                    setShowDetails(true);
                }
            });
        } else {
            setMessage('Por favor preencha o campo de email!');
        }
    }

    // Efetua o login
    const login = () => {
        console.log('Logging...');
        if (name.trim() !== '' && email.trim() !== '') {
            const userData = {
                "id": id,
                "photo": "",
                "name": name.trim(),
                "email": email.trim(),
                "celular": celular.trim(),
            };
            User.login(userData).then((result) => {
                console.log(result);
                setShowOtp(true);
                checkOTP();
            });
        } else if (showDetails) {
            setMessage('Por favor preencha todos os campos.')
        }
    }

    // Caso email já esteja cadastrado efetua o login
    useEffect(login, [doLogin]);

    // Reload a página no caso do usuário ser verificado
    useEffect(() => {
        if (!open && verified) {
            window.location.reload();
        }
    }, [open, verified])

    // Checa a otp
    const checkOTP = () => {
        console.log('Verifying email...');
        User.getByEmail(email).then((result) => {
            console.log(result);
            if (result.response.length > 0) {
                const user = result.response[0];
                if (user.active === 0) {
                    setTimeout(() => { checkOTP(email); }, 5000);
                } else {
                    localStorage.setItem('token', CryptoJS.MD5(email).toString());
                    Global.userToken = CryptoJS.MD5(email).toString();
                    setVerified(true);
                    console.log('Email verified!');
                }
            } else {
                // TODO ERROR
            }
        });
    }

    // Campo de email e outros
    const fields = () => (
        showDetails ? <div>
            <TextField
                id="txtEmail"
                size="small"
                label="Email"
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            {details()}
        </div> : <TextField
                id="txtEmail"
                size="small"
                label="Email"
                fullWidth
                margin="normal"
                variant="outlined"
                value={email}
                style={{ marginBottom: 18 }}
                onChange={(event) => setEmail(event.target.value)}
            />
    )

    // Botão cancelar
    const onCancel = () => {
        setName('');
        setCelular('');
        setShowOtp(false);
        setShowDetails(false);
        setID(0);
        setEmail('');
    }

    // Botão ok
    const onBtnOk = () => {
        setOpen(false);
    }

    // Campos com detalhes do usuário
    const details = () => (<div>
        <TextField
            id="txtName"
            size="small"
            label="Nome completo"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
        />
        <TextField
            id="txtPhone"
            size="small"
            label="Celular"
            fullWidth
            margin="normal"
            variant="outlined"
            value={celular}
            onChange={(event) => setCelular(event.target.value)}
        />
    </div>)

    // Aguardando confirmação do email
    const otpField = () => (
        <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ marginTop: 24, marginBottom: 48 }} />
            <Typography gutterBottom style={{ marginBottom: 24 }}>
                Por favor mantenha essa janela aberta e siga as orientações que foram enviadas para <b><i>{email}</i></b> a fim de confirmar seu cadastro. Verifique também na caixa de SPAM. Caso não tenha recebido, tente novamente.
            </Typography>
        </div>
    )

    // Email verificado
    const verifiedFields = () => (
        <div style={{ textAlign: 'center' }}>
            <VerifiedIcon style={{ fontSize: 90, color: "#00a000" }} />
            <Typography gutterBottom style={{ marginBottom: 24, marginTop: 48 }}>
                <b>Seja bem-vindo!</b><br />Agora você pode participar das votações.
            </Typography>
        </div>
    )

    return (
        <div>
            <Dialog fullWidth={true} maxWidth='sm' onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    {!verified ? (showOtp ? "Aguardando confirmação" : "Dados Pessoais") : "Acesso Confirmado!"}
                </DialogTitle>
                <DialogContent dividers>
                    {verified ?
                        verifiedFields()
                        :
                        (!showOtp ?
                            fields()
                            :
                            otpField())
                    }
                    {message !== '' ? <Alert severity="error">{message}</Alert> : null}
                </DialogContent>
                <DialogActions>
                    {!showOtp ? (showDetails ?
                        <Button autoFocus onClick={() => login()}>
                            Próximo
                        </Button>
                        :
                        <Button autoFocus onClick={() => checkEmail()}>
                            Próximo
                        </Button>)
                        : (
                            !verified ?
                                <Button autoFocus onClick={() => onCancel()}>
                                    Tentar novamente
                                </Button>
                                :
                                <Button autoFocus onClick={() => onBtnOk()}>
                                    Concluir
                                </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}