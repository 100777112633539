import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, Card, CardContent, CardActions, Button, CardHeader, Snackbar, SnackbarContent, Avatar, withStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Global from '../Globals';
import Voting from '../models/Voting';
import Subjects from '../models/Subjects';
import Results from '../models/Results';
import ResultsTable from './ResultsTable';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
        margin: 'auto',
        borderRadius: 20
    },
    button: {
        borderRadius: 20,
        paddingLeft: 20,
        paddingRight: 20,
        margin: 'auto',
        marginBottom: 16,
        marginTop: 16
    },
    avatar: {
        marginRight: 8
    },
    item: {
        border: 'none',
        color: '#303030',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        align: 'left',
        paddingLeft: 48,
        paddingRight: 48
    },
    div: {
        width: '100%',
        textAlign: 'center',
        marginTop: 24
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: '10px',
            textAlign: 'left'
        },
        '&:first-child': {
            borderRadius: '10px',
            textAlign: 'left'
        },
    },
}))(ToggleButtonGroup);

export default function VotingArea(props) {
    const classes = useStyles();
    const previousStatus = useRef('');
    const [voting, setVoting] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [option, setOption] = useState(null);
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState(false);
    const [voted, setVoted] = useState(false);

    // Verifica status da votação
    const checkAvailableVoting = () => {
        Voting.get(Global.meeting.id).then(result => {
            console.log(result);
            if (result != null) {
                setVoting(result);
            }
        });
    }

    // Atualiza votação de acordo com o status
    useEffect(() => {
        checkAvailableVoting();
    }, []);

    // Status da votação mudou
    useEffect(() => {
        if (voting != null && previousStatus.current !== voting.status) {
            previousStatus.current = voting.status;
            if (voting.status === 'Aguardando' || voting.status === 'Cancelada') {
                // Aguardando ou cancelada
                setSubjects([]);
                setOption(null);
                setResults([]);
            } else if (voting.status === 'Ativa' && voting.voted === 0) {
                // Votando, pega subjects
                Subjects.get(voting.id).then((result) => {
                    if (result != null) {
                        setSubjects(result.response);
                    }
                });
            } else if (voting.status === 'Encerrada') {
                // Mostra o resultado
                Results.get(voting.id).then(result => {
                    if (result.error == null) {
                        setResults(result.response);
                    }
                });
            }
        }
        //voting == null || setTimeout(checkAvailableVoting, 1000);
    }, [voting]);

    // Botões de opções
    const selectOption = (event, nextOption) => {
        setOption(nextOption);
    };

    // Votar
    const onVotarBtn = () => {
        if (option != null) {
            setVoted(true);
            const data = {
                id: 0,
                voting_id: voting.id,
                user_id: Global.user.id,
                subject_id: option,
            };
            Results.save(data).then(result => {
                if (result != null && result.error == null) {
                    setMessage(true);
                } else if (result != null && result.error) {
                    setVoted(false);
                }
                checkAvailableVoting();
            });
        }
    }

    // Voting Card
    const votingCard = () => (
        <Card className={classes.root}>
            <CardHeader title={voting.title.toUpperCase()} />
            <CardContent>
                <StyledToggleButtonGroup orientation="vertical" value={option} exclusive onChange={selectOption}>
                    {
                        subjects.map((subject) => (
                            <ToggleButton className={classes.item} key={subject.id} value={subject.id} aria-label={subject.subject}>
                                <Avatar 
                                    className={classes.avatar} 
                                    style={{ backgroundColor: subject.subject==='Branco' ? '#e0e0e0' : (subject.subject==='Nulo' ? '#404040' : Global.institution.primary_color) }}
                                >
                                    {subject.subject.substr(0, 1)}
                                </Avatar>
                                <Typography>
                                    {subject.subject}
                                </Typography>
                            </ToggleButton>
                        ))
                    }
                </StyledToggleButtonGroup>
            </CardContent>
            {Global.meeting.user_type !== 'Participante' ? null :
                <CardActions>
                    {
                        option != null && !voted ? (<Button
                            color='primary'
                            variant='contained'
                            className={classes.button}
                            onClick={onVotarBtn}
                        >
                            Confirmar
                        </Button>) : (
                                <Button
                                    color='primary'
                                    variant='contained'
                                    className={classes.button}
                                    disabled
                                >
                                    Confirmar
                                </Button>
                            )
                    }
                </CardActions>
            }
        </Card>
    )

    // Aguardando votação
    const waitingVoting = () => (
        <div style={{ color: Global.institution.primary_color, textAlign: 'center' }}>
            <CircularProgress style={{ color: Global.institution.primary_color, marginTop: 120, marginBottom: 14 }} />
            <Typography style={{ fontSize: 10, fontWeight: 'bold' }}>
                AGUARDANDO VOTAÇÃO
            </Typography>
        </div>
    )

    // Aguardando Resultados
    const waitingResults = () => (
        <div style={{ color: Global.institution.primary_color, textAlign: 'center' }}>
            <CircularProgress style={{ color: Global.institution.primary_color, marginTop: 120, marginBottom: 14 }} />
            <Typography style={{ fontSize: 10, fontWeight: 'bold' }}>
                AGUARDANDO RESULTADO
            </Typography>
        </div>
    )

    // Resultados
    const resultsView = () => (
        <ResultsTable results={results} voting={voting}/>
    )

    // Content
    const getContent = () => {
        if (voting == null || voting.status === 'Aguardando' || voting.status === 'Cancelada') {
            return waitingVoting();
        } else if (voting.status === 'Ativa' && voting.voted === 0) {
            return votingCard();
        } else if (voting.status === 'Ativa' && voting.voted !== 0) {
            return waitingResults();
        } else if (voting.status === 'Encerrada') {
            return resultsView();
        }
    }

    return (
        <div className={classes.div}>
            {  getContent()}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={message} autoHideDuration={6000} onClose={() => setMessage(false)} >
                <SnackbarContent style={{ backgroundColor: '#00aa00', color: '#ffffff' }} message='Voto computado com sucesso!' />
            </Snackbar>
        </div>
    )

}
