import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Global from '../Globals';
import Meetings from '../models/Meetings';
import Utils from '../utils/utils';

const DialogContent = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        width: 480
    },
}))(MuiDialogContent);

const TextField = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))(MuiTextField);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MeetingEditDialog(props) {
    const [meeting, setMeeting] = React.useState(props.meeting === null ? {} : props.meeting);

    // Edição
    const onChange = (event) => {
        setMeeting({ ...meeting, [event.target.name]: event.target.value })
    }

    // Salvar
    const onSaveBtn = () => {
        const data = {
            id: meeting.id,
            institution_id: Global.institution.id,
            date_start: Utils.strDateToMysql(meeting.date_start),
            date_end: Utils.strDateToMysql(meeting.date_end),
            title: meeting.title,
            local: meeting.local,
            status: meeting.status,
            active: meeting.active,
            link: meeting.link,
            deleted: meeting.deleted
        };
        Meetings.save(data).then(result => {
            console.log(result);
            props.onClose();
        });
    }

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {meeting.id === 0 ? "Adicionar Reunião" : "Editar Reunião"}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="title"
                    required
                    fullWidth
                    variant="outlined"
                    label="Título"
                    value={meeting.title}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="local"
                    fullWidth
                    variant="outlined"
                    label="Local"
                    value={meeting.local}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="date_start"
                    fullWidth
                    variant="outlined"
                    label="Data de início"
                    value={meeting.date_start}
                    onChange={event => onChange(event)}
                />
                <TextField
                    name="date_end"
                    fullWidth
                    variant="outlined"
                    label="Data de encerramento"
                    value={meeting.date_end}
                    onChange={event => onChange(event)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} style={{ color: Global.institution.primary_color }}>
                    Fechar
                </Button>
                <Button onClick={onSaveBtn} style={{ color: Global.institution.primary_color }}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
