import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Global from '../Globals';
import Voting from '../models/Voting';

const DialogContent = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        width: 480
    },
}))(MuiDialogContent);

const TextField = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))(MuiTextField);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VotingEditDialog(props) {
    const [voting, setVoting] = React.useState(props.voting === null ? {} : props.voting);

    // Edição
    const onChange = (event) => {
        setVoting({ ...voting, [event.target.name]: event.target.value })
    }

    // Salvar
    const onSaveBtn = () => {
        const data = {
            id: voting.id,
            meeting_id: props.meeting.id,
            title: voting.title,
            status: voting.status,
            active: voting.active,
            deleted: voting.deleted
        };
        Voting.save(data).then(result => {
            console.log(result);
            props.onClose();
        });
    }

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {voting===null || voting.id === undefined || voting.id === 0 ? "Adicionar Votação" : "Editar Votação"}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="title"
                    required
                    fullWidth
                    variant="outlined"
                    label="Título"
                    value={voting.title}
                    onChange={event => onChange(event)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} style={{ color: Global.institution.primary_color }}>
                    Fechar
                </Button>
                <Button onClick={onSaveBtn} style={{ color: Global.institution.primary_color }}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
