
// Date format
const Utils = { 

    dateFormat : (dateStr) => new Date(dateStr).toLocaleString('pt-BR', {
        dateStyle: "short",
        timeStyle: "short",
        timeZone: 'America/Sao_Paulo'
    }).substr(0, 16),

    timeFormat : (dateStr) => new Date(dateStr).toLocaleTimeString('pt-BR', {
        timeStyle: "short",
        timeZone: 'America/Sao_Paulo'
    }).substr(0, 5),

    dateToMysql : (date) => (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 19).replace('T', ' '),

    strDateToMysql : (strDate) => {
        const [date, time] = strDate.split(' ');
        const [d, m, a] = date.split('/');
        return `${a}-${m}-${d} ${time}`;
    }

}

export default Utils;