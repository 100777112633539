import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Global from '../Globals';
import Subjects from '../models/Subjects';

const DialogContent = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-start',
        width: 480
    },
}))(MuiDialogContent);

const TextField = withStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))(MuiTextField);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubjectEditDialog(props) {
    const [subject, setSubject] = React.useState(null);

    // Edição
    const onChange = (event) => {
        setSubject({ ...subject, [event.target.name]: event.target.value })
    }

    // Salvar
    const onSaveBtn = () => {
        const data = {
            id: 0,
            voting_id: props.voting.id,
            subject: subject.subject,
            content: ' ',
            image: ' ',
        };
        Subjects.save(data).then(result => {
            console.log(result);
            setSubject(null);
            props.onClose();
        });
    }

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                Adicionar Item
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="subject"
                    required
                    fullWidth
                    variant="outlined"
                    label="Item"
                    value={subject!==null ? subject.subject : ''}
                    onChange={event => onChange(event)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} style={{ color: Global.institution.primary_color }}>
                    Fechar
                </Button>
                <Button onClick={onSaveBtn} style={{ color: Global.institution.primary_color }}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
