import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Avatar, Card, CardContent, CardHeader } from '@material-ui/core';
import Global from '../Globals';

const useStyles = makeStyles({
    root: {
        width: '90%',
        margin: 'auto',
        borderRadius: 20
    },
    table: {
        width: "100%"
    }
});

export default function ResultsTable(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader title={props.voting.title.toUpperCase() + ' • RESULTADO'} />
            <CardContent>
                <Table className={classes.table} aria-label="resultados">
                    <TableBody>
                        {props.results.map((row) => (
                            <TableRow key={row.subject}>
                                <TableCell component="th" scope="row" width={24} align="right">
                                    <Avatar style={{ backgroundColor: row.subject==='Branco' ? '#e0e0e0' : (row.subject==='Nulo' ? '#404040' : Global.institution.primary_color)  }}>
                                        {row.subject.substr(0, 1)}
                                    </Avatar>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.subject}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }} align="right">
                                    {row.votes} {row.votes === 1 ? 'voto' : 'votos'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
